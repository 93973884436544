import React, {useEffect} from 'react'
import Page from '../components/Page'
import './Home.scss';
import {useTranslation} from "react-i18next";
import {IonButton} from "@ionic/react";
import {useHistory} from "react-router";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";

import Flags from 'country-flag-icons/react/3x2'

import Spinner from "../components/Spinner";
import LogoHeader from "./LogoHeader";
import ProgrammeFooter from "./ProgrammeFooter";
import {toggleDarkAxis, toggleDarkFund} from "../scripts/helpingFunctions.js";
//import {count} from "../App"

const Home = (context) => {
    const {t} = useTranslation('welcome');
    const history = useHistory();

    const locale = useSelector(state => state.settings.locale);
    const api_status = useSelector(state => locale ? state.apidata[locale].status :  '');

    const getCountryIcon = country_code => {
        return React.createElement(Flags[country_code.toUpperCase()], {
            key: country_code,
            height: '1.5em', className: 'icon'
        });
    }

    useEffect(() => {
        toggleDarkAxis();
        toggleDarkFund();
    }, []);

    const generateBody = () => {
        return <div className='action'>
            <IonButton className='explore' color='success' onclick={() => history.push('/search')}>
                {t('action')}
                <FontAwesomeIcon icon={faChevronRight} style={{marginLeft: '4px'}} />
            </IonButton>
            {/*<IonButton onLoadedData={() => {toggleDarkAxis()}} className='proximity' color='success' onclick={() => history.push('/proximity')}>
                {t('proximity')}
                <FontAwesomeIcon icon={faChevronRight} style={{marginLeft: '4px'}} />
            </IonButton>*/}
        </div>
    }

    return <Page title='Home' className='home' bare={true}
                 header={<LogoHeader />}
                 footer={<ProgrammeFooter />}
    >
        <div className='content'>
            <div className='main'>

                { api_status === 'loaded' ?
                    generateBody()
                    : <div className='center-content home-loading'><Spinner dark={true}/></div>
                }
            </div>
            <div className='message'>
                { t('intro') }
                <div className='countries'>
                    { ['it','gr'].map( countryCode => getCountryIcon(countryCode) ) }
                </div>
            </div>
            <div className='info-help'>
                {/*<IonButton color='success' /*</div>onclick={() => window.open(manual_url,
                    '_system', 'location=yes')}/>
                    <FontAwesomeIcon icon={faInfoCircle} style={{marginRight: '4px'}} />
                    {t('manual')}
                </IonButton>*/}
                <IonButton color='success' onclick={() => history.push('/help')}>
                    <FontAwesomeIcon icon={faInfoCircle} style={{marginRight: '4px'}} />
                    {t('help')}
                </IonButton>
            </div>
        </div>
    </Page>;
};

export default Home;