import {createAction, createReducer} from "@reduxjs/toolkit";

const setRouteCategories = createAction('SET_ROUTE_CATEGORIES');

const initialState = [];

const reducer = createReducer(initialState, {
    [setRouteCategories]: (state, action) => action.payload
});

export {setRouteCategories};
export default reducer;