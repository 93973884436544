import React, {Fragment} from 'react';
import {IonButton} from "@ionic/react";
import {connect} from 'react-redux';
import {setLocale} from '../store/modules/settings'
import Flags from 'country-flag-icons/react/3x2'

const LanguageSelect = (props, context) => {
    const onChange = props.onChange || new Function();
    const getFlag = lang => {
        const style = {width: '60px', height: '40px'};
        switch (lang) {
            case 'it': return <Flags.IT style={style} />;
            case 'en': return <Flags.GB style={style} />;
        }

        return '';
    };

    const makeColor = locale =>
        locale === props.currentLocale ? 'primary' : '';

    const clickedLang = lang => {
        props.setLocale(lang);
        onChange();
    }

    return <Fragment>
        <div className="container">
            { ['en', 'it' ].map(
                lang => <IonButton className="language-button" slot="icon-only" key={lang} onClick={() => clickedLang(lang)} color={makeColor(lang)}>
                    { getFlag(lang) }
                </IonButton>
            ) }
        </div>
    </Fragment>;
};


export default connect(
    state => ({
        currentLocale: state.settings.locale
    }),
    dispatch => ({
        setLocale: lang => dispatch(setLocale(lang))
    })
)(LanguageSelect);