import React from 'react'
import eu from "../resources/eu.png";
import {useTranslation} from "react-i18next";
import {toggleDarkFund} from "../scripts/helpingFunctions.js";

import {faMap, faHome, faHeart, faMapMarkerAlt, faThList, faCog} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useHistory} from "react-router";
import {cycleLocale} from "../i18n";
import {IonTabBar, IonTabButton,} from "@ionic/react";

/*const partners = [
    require('../resources/bregdetit.jpg'),
    require('../resources/molfetta.jpg'),
    require('../resources/termoli.png'),
    require('../resources/puglia.jpg'),
    require('../resources/ministria.jpg'),
    require('../resources/PORT_HERCEG_NOVI_LOGO.png'),
];*/

const partners = [
    require('../resources/samples/LB.png'),
    require('../resources/samples/PB1.png'),
    require('../resources/samples/PB2.png'),
    require('../resources/samples/PB3.png'),
    require('../resources/samples/PB4.png'),
    require('../resources/samples/PB5.png'),
];

const ProgrammeFooter = (props) => {
    const {t} = useTranslation('welcome');

    const history = useHistory();

    const handleTabChange = function(ev) {
        console.log('tabs changed', ev);
        if (ev.detail.tab === 'locale') {
            ev.stopImmediatePropagation();

            cycleLocale();
        }
    }

    return <div className='bottom'>
        <div className='partners'>
            {/*partners.map(partner_image => {
                <div key={partner_image}><img src={partner_image} key={partner_image} /></div>
            }
        )*/}
        <div key={partners[0]}><img src={partners[0]} key={partners[0]} /></div>
        {/*<div key={partners[1]}><img src={partners[1]} key={partners[1]} /></div>*/}
        <div key={partners[2]}><img src={partners[2]} key={partners[2]} /></div>
        <div key={partners[3]}><img src={partners[3]} key={partners[3]} /></div>
        <div key={partners[4]}><img src={partners[4]} key={partners[4]} /></div>
        <div key={partners[5]}><img src={partners[5]} key={partners[5]} /></div>
        </div>
        <div className='eu-fund' id='fund'>
            <img src={eu} style={{height: '2em'}}/>
            <span>{t('This project is co-financed by the European Union under the Instrument for Pre-Accession Assistance (IPA II)')}</span>
        </div>

        { props.bare ? props.footer :
            <IonTabBar slot="bottom" onIonTabsWillChange={handleTabChange}>
                <IonTabButton tab='menu' onclick={() => {history.push("/search")}} href='/search'>
                    <FontAwesomeIcon icon={faThList} size="2x"/>
                </IonTabButton>
                <IonTabButton tab='routes' onclick={() => {history.push("/routes")}} href="/routes">
                    <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" />
                </IonTabButton>
                <IonTabButton tab='home' onclick={() => {history.push("/home")}} href="/home">
                    <FontAwesomeIcon icon={faHome} size="2x" />
                </IonTabButton>
                <IonTabButton hidden tab='favorites' onclick={() => {history.push("/favorites")}} href="/favorites">
                    <FontAwesomeIcon icon={faHeart} size="2x" />
                </IonTabButton>
                <IonTabButton tab='map' onclick={() => {history.push("/map")}} href="/map">
                    <FontAwesomeIcon icon={faMap} size="2x" />
                </IonTabButton>
                <IonTabButton tab='settings' onClick={() =>{history.push("/settings")}} href="/settings">
                    <FontAwesomeIcon icon={faCog} size="2x" />
                </IonTabButton>
            </IonTabBar>
        }
    </div>
}

export default ProgrammeFooter;