import {createAction, createReducer} from "@reduxjs/toolkit";
import {points} from "../../data/en";

const setPoints = createAction('SET_POINTS');

//const initialState = extractPointData('en');
const initialState = [];

const reducer = createReducer(initialState, {
    [setPoints]: (state, action) => action.payload
});

export {setPoints};
export default reducer;