import React from 'react'
import {useSelector} from "react-redux";
import Spinner from "./Spinner";

const WaitData = (props) => {
    const locale = useSelector(state => state.settings.locale);
    const api_status = useSelector(state => locale ? state.apidata[locale].status : '');

    if (api_status === 'loaded')
        return props.children;

    return <div className='center-content'>
        <Spinner />
    </div>;
}

export default WaitData;