import React from 'react';
import {
    IonContent,
    IonHeader,
    IonPage, IonTabBar, IonTabButton,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import {faMap, faHome, faHeart, faThList, faCog, faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useHistory} from "react-router";
import {cycleLocale} from "../i18n";
import { App as CapacitorApp } from '@capacitor/app';

const Page = (props = {title: '', bare: false, header: '', footer: ''}) => {
    const history = useHistory();

    const handleTabChange = function(ev) {
        console.log('tabs changed', ev);
        if (ev.detail.tab === 'locale') {
            ev.stopImmediatePropagation();

            cycleLocale();
        }
    }

    CapacitorApp.addListener('backButton', ({canGoBack}) => {
        if(!canGoBack){
          CapacitorApp.exitApp();
        } else {
          window.history.back();
        }
      });

    return <IonPage className={props.className}>
        { props.bare ? props.header :
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{props.title}</IonTitle>
                    {
                        props.toolbar ? props.toolbar : ''
                    }
                </IonToolbar>
            </IonHeader>
        }

        <IonContent>
            {props.children}
        </IonContent>

        { props.bare ? props.footer :
            <IonTabBar slot="bottom" onIonTabsWillChange={handleTabChange}>
                <IonTabButton tab='menu' onclick={() => {history.push("/search")}} href='/search'>
                    <FontAwesomeIcon icon={faThList} size="2x"/>
                </IonTabButton>
                <IonTabButton tab='routes' onclick={() => {history.push("/routes")}} href="/routes">
                    <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" />
                </IonTabButton>
                <IonTabButton tab='home' onclick={() => {history.push("/home")}} href="/home">
                    <FontAwesomeIcon icon={faHome} size="2x" />
                </IonTabButton>
                <IonTabButton hidden tab='favorites' onclick={() => {history.push("/favorites")}} href="/favorites">
                    <FontAwesomeIcon icon={faHeart} size="2x" />
                </IonTabButton>
                <IonTabButton tab='map' onclick={() => {history.push("/map")}} href="/map">
                    <FontAwesomeIcon icon={faMap} size="2x" />
                </IonTabButton>
                <IonTabButton tab='settings' onClick={() =>{history.push("/settings")}} href="/settings">
                    <FontAwesomeIcon icon={faCog} size="2x" />
                </IonTabButton>
            </IonTabBar>
        }
    </IonPage>;
};

export default Page;
