import {createAction, createReducer} from "@reduxjs/toolkit";
import {changeLocale} from '../../i18n'
import {some} from 'lodash'

const setLocale = createAction('SET_LOCALE');

const matchLocales = function(needle, haystack) {
    const reg = new RegExp( '^' + needle);
    return some(
        haystack.map(
            locale => reg.test(locale)
        )
    );
}

const initialState = {
    locale: matchLocales('it', navigator.languages) ? 'it' : 'en',
};

const reducer = createReducer(initialState,{
    [setLocale]: (state, action) => {
        // console.log('here');
        // if (state.locale !== action.payload)
        //     changeLocale(action.payload);
        return {...state, locale: action.payload};
    },
});

export {setLocale};
export default reducer;