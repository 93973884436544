import React, {useEffect} from 'react';
import { Redirect, Route } from 'react-router-dom';
import {IonRouterOutlet} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/styles.scss';
import {Switch} from "react-router";
import Search from "./pages/Search";
import Results from "./pages/Results";
import Map from "./pages/Map";
import Settings from "./pages/Settings";
import initI18n from "./i18n";
import {Translation} from "react-i18next";
import GISMap from "./pages/GISMap";
import Favorites from "./pages/Favorites";
import Routes from "./pages/Routes";
import {useDispatch, useSelector} from "react-redux";
import {loadApiData} from "./store/modules/apidata";
import LanguageSelect from "./components/LanguageSelect";
import Page from "./components/Page";
import WaitData from "./components/WaitData";
import LogoHeader from "./pages/LogoHeader";
import ProgrammeFooter from "./pages/ProgrammeFooter";
import Help from "./pages/Help";
import RoutesByProximity from "./pages/RoutesByProximity";

const App = (props, context) => {
    // Use matchMedia to check the user preference
    //const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    //const toggle = document.querySelector('#themeToggle');

    const locale = useSelector(state => state.settings.locale);
    const apidata = useSelector(state => state.apidata);

    const api_status = apidata[locale];

    const dispatch = useDispatch();

    useEffect(() => {
        initI18n(locale);
    }, [locale]);

    useEffect(() => {
        if (locale) {
            if (api_status !== 'loaded') {
                console.log('Loading from API');
                dispatch(loadApiData(locale));
            }
        }
    }, [locale]);

    if (!locale)
        return <Page bare={true}
                     className='language-page'
                     header={<LogoHeader />}
                     footer={<ProgrammeFooter />}
            >
            <div className='center-content language-modal' style={{flexDirection: 'column'}}>
                <div style={{textAlign: 'center', marginBottom: '0.5em'}}>
                    <div>Please select your language:</div>
                    <div>Seleziona una lingua:</div>
                </div>
                <LanguageSelect />
            </div>
        </Page>;

    return <IonReactRouter>
        <IonRouterOutlet>
            <Translation>
                {
                    (t, { i18n }) =>
                        <Switch>
                            <Route path="/home" component={Home} exact={true}/>

                            <Route path="/search" render={() => <WaitData><Search /></WaitData>} exact={true}/>
                            <Route path="/routes" render={() => <WaitData><Routes /></WaitData>} exact={true}/>
                            <Route path="/proximity" render={() => <WaitData><RoutesByProximity /></WaitData>} exact={true}/>
                            <Route path="/results" render={() => <WaitData><Results /></WaitData>} exact={true}/>
                            <Route path="/favorites" render={() => <WaitData><Favorites /></WaitData>} exact={true}/>
                            <Route path="/help" render={() => <Help />} exact={true}/>
                            <Route path="/gis/routes/:route_id" render={() => <WaitData><GISMap /></WaitData>} exact={true}/>
                            <Route path="/map" render={() => <WaitData><Map /></WaitData>} exact={true}/>
                            <Route path="/settings" render={() => <WaitData><Settings /></WaitData>} exact={true}/>

                            <Route path="/" render={() => <WaitData><Home /></WaitData>} exact={true}/>{/*<Redirect to="/home"/>*/}
                        </Switch>
                }
            </Translation>
        </IonRouterOutlet>
    </IonReactRouter>;
}

export default App;
