import {createAction, createReducer} from "@reduxjs/toolkit";

const setRoutes = createAction('SET_ROUTES');

const initialState = [];

const reducer = createReducer(initialState, {
    [setRoutes]: (state, action) => action.payload
});

export {setRoutes};
export default reducer;