import {createAction, createReducer} from "@reduxjs/toolkit";

const setCountries = createAction('SET_COUNTRIES');

const initialState = [];

const reducer = createReducer(initialState, {
    [setCountries]: (state, action) => action.payload
});

export {setCountries};
export default reducer;