import React from 'react'
import {useSelector} from "react-redux";
import {calculateMatchingPoints, calculateMatchingRoutes} from "../services/search";
import RouteList from "./RouteList";
import Page from "../components/Page";
import {useTranslation} from "react-i18next";

const Results = (props, context) => {
    const searchData = useSelector(state => state.search);
    const {t} = useTranslation('pages');
    const filteredRoutes = calculateMatchingPoints();

    return <Page title={t('Search Results')}>
        <RouteList points={filteredRoutes} />
    </Page>;
}

export default Results;