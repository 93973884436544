import React from 'react'
import Page from "../components/Page";
import RouteList from "./RouteList";
import {useTranslation} from "react-i18next";

const Routes = (props, context) => {
    const {t} = useTranslation('pages');

    return <Page title={ t('All Routes') }>
        <RouteList />
    </Page>
}

export default Routes;