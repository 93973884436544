import {applyMiddleware, combineReducers, createStore, compose} from "redux";
import {configureStore} from "@reduxjs/toolkit";
import {TypedUseSelectorHook, useSelector} from "react-redux";
import ReduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

// Persistency
import {persistStore, persistCombineReducers, createMigrate} from 'redux-persist'
import {default as persistedLocalStorage} from 'redux-persist/lib/storage'

// Modules
import favorites from './modules/favorites'
import search from './modules/search'
import points from './modules/points'
import routes from './modules/routes'
//import geo from './modules/geo'
import countries from './modules/countries'
import settings, {setLocale} from './modules/settings'
import categories from './modules/categories'
import routecategories from './modules/routecategories'
import apidata from './modules/apidata'
import ways from './modules/ways'
import {changeLocale} from "../i18n";

const migrations = {
    2: state => ({
        ...state,
        search: {
            ...state.search,
            accessibilityOptions: [],
            childrenFriendlyOptions: []
        }
    }),
    3: state => ({
        ...state,
        search: {
            ...state.search,
            routecategories: [],
            tripLength: {lower: 50, upper: 300},
            tripTime: {lower: 1, upper: 5},
        }
    }),
    4: state => ({
        ...state,
        search: {
            ...state.search,
            includedPoints: []
        }
    }),
    5: state => ({
        ...state,
        search: {}
    }),
    6: state => ({
        ...state,
        search: {
            ...state.search,
            userDistance: 50
        }
    }),
    7: state => ({
        ...state,
        search: {
            ...state.search,
            accessibilityOptions: false,
            childrenFriendlyOptions: false
        }
    })
};
const persistConfig = {
    key: 'root',
    storage: persistedLocalStorage,
    whitelist: ['favorites', 'search','settings'],
    version: 7,
    migrate: createMigrate(migrations, {debug: true})
}

const middleware = [
    ReduxThunk,
    store => next => action => {
    if (action.type == setLocale)
        changeLocale(action.payload)

        next(action);
    }
]

const persistedRootReducer = persistCombineReducers(persistConfig,{
    favorites,
    search,
    points,
    routes,
    //geo,
    countries,
    settings,
    apidata,
    categories,
    routecategories,
    ways
});


const store = createStore(
    persistedRootReducer,
    composeWithDevTools(
        applyMiddleware(...middleware)
    )
);

const persistor = persistStore(store);
const selector = useSelector;

export {selector, persistor};
export default store;
