import React from 'react'
import Page from "../components/Page";
import RouteList from "./RouteList";
import {useTranslation} from "react-i18next";
//import {filterPointsByProximity, getGeoCoords} from "../services/search";
import {useSelector} from "react-redux";

const Routes = (props, context) => {
    const {t: tPages} = useTranslation('pages');
    const {t} = useTranslation('listing');
    //const isGeoAvailable = useSelector(state => getGeoCoords(state));

    //const filteredRoutes = useSelector(state => isGeoAvailable ? filterPointsByProximity(state, state.points, 50) : null);

    const makeInfo = text => <div className='center-content' style={{ padding: 40, textAlign: 'center'}}>{text}</div>;

    return <Page title={ tPages('Closest Routes') }>
        {/*isGeoAvailable ?
            ( filteredRoutes.length
                ? <RouteList routes={filteredRoutes}/> :
                makeInfo(t('no_results'))
            ) : makeInfo(t('geolocation_not_available'))*/
        }
    </Page>
}

export default Routes;