import i18n from "i18next";
import {getI18n, initReactI18next} from "react-i18next";

import {default as store} from '../store';

import en from './en'
import it from './it'
import {setLocale} from "../store/modules/settings";

const initI18n = (currentLocale = 'en') => {
    i18n.use(initReactI18next)
        .init({
            resources: {
                en, it
            },
            lng: (currentLocale !== '') ? currentLocale : 'en',
            fallbackLng: 'en',
        });

    window.i18n = i18n;
}

const changeLocale = function(locale) {
    getI18n().changeLanguage(locale);
};

const cycleLocale = function() {
    const locales = ['en', 'it'];

    const localeIndex = (locales.indexOf(store.getState().settings.locale) + 1) % locales.length;

    store.dispatch(setLocale(locales[localeIndex]));
}

export default initI18n;
export {changeLocale,cycleLocale};