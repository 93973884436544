import React, {useEffect, useRef, useState} from 'react';
import {throttle,forEach} from "lodash";
import {Marker, Polyline, Map, TileLayer, Circle} from "react-leaflet";
import {useDispatch, useSelector} from "react-redux";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet'
//import {startWatch} from "../services/geo";
import {IonButton, IonButtons, IonIcon} from "@ionic/react";
import Page from "../components/Page";
import {faMapMarkedAlt, faMapMarker} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {heart, heartOutline} from "ionicons/icons";
import {toggleFavorited} from "../store/modules/favorites";
import { InView } from 'react-intersection-observer';
import {useRouteMatch} from "react-router";
import classNames from 'classnames/bind';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

import './Map.scss';

//startWatch();


delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const HereIcon = L.icon({
    iconUrl:  require('../resources/pointmappin.png'),
    iconRetinaUrl:  require('../resources/pointmappin.png'),
    iconSize:     [40, 40],
    iconAnchor: [20, 20],
});

const iconMapping = {
    'activity.png': require('../resources/points/activity.png'),
    'biking.png': require('../resources/points/biking.png'),
    'church.png': require('../resources/points/church.png'),
    'culture.png': require('../resources/points/culture.png'),
    'default': require('../resources/points/default.png'),
    'fortress.png': require('../resources/points/fortress.png'),
    'gastronomy.png': require('../resources/points/gastronomy.png'),
    'hiking.png': require('../resources/points/hiking.png'),
    'hotel.png': require('../resources/points/hotel.png'),
    'isole.png': require('../resources/points/isole.png'),
    'lake.png': require('../resources/points/lake.png'),
    'marina.png': require('../resources/points/marina.png'),
    'molisian_croatian.png': require('../resources/points/molisian_croatian.png'),
    'museum.png': require('../resources/points/museum.png'),
    'nature.png': require('../resources/points/nature.png'),
    'nature-reserve.png': require('../resources/points/nature-reserve.png'),
    'park.png': require('../resources/points/park.png'),
    'port.png': require('../resources/points/port.png'),
    'river.png': require('../resources/points/river.png'),
    'seaside.png': require('../resources/points/seaside.png'),
    'town.png': require('../resources/points/town.png'),
    'urban-architecture.png': require('../resources/points/urban-architecture.png')
};

const smallIcons = {};
const largeIcons = {};

forEach(iconMapping, (image, iconName) => {
        smallIcons[iconName] = L.icon({
            iconUrl:  image,
            iconRetinaUrl: image,
            iconSize: [35, 35],
            iconAnchor: [17.5, 17.5],
        });

        largeIcons[iconName] = L.icon({
            iconUrl: image,
            iconRetinaUrl: image,
            iconSize: [52, 52],
            iconAnchor: [26, 26],
        });
    }
);

const GISMap = () => {
    let theMap = useRef();
    //let zoomlvl = 6;
    //let cntr = [39.618095, 18.029649];
    const didMountRef = useRef(false);
    const match = useRouteMatch();
    const route_id = parseInt(match.params.route_id);
    //const point_id=parseInt(match.params.point_id);
    const dispatch = useDispatch();

    //const {t} = useTranslation('gis');

    const modalRoot = useRef();

    //const currentPointTextRef = useRef();

    const favoriteRoutes = useSelector(state => state.favorites);

    let [polylinePositions, setPolylinePositions] = useState([]);
    let [modalCollapsed, setModalCollapsed] = useState(true);
    const collapseModal = function() {
        setModalCollapsed(!modalCollapsed);
    }

    //const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

    const [prefersDark,setPrefersDark] = useState(false);

    useEffect(() => {
        if(document.body.classList.length>0)
        {
            setPrefersDark(true);    
        }
        else
        {
            setPrefersDark(false);
        }
    }, []);

    //const routeData = useSelector(state => state.routes.find(route => route.id === route_id));
    const pointsData = useSelector(state => {
        return state.points;//.filter(point => routeData.points.includes(point.id));
    });

    const pointCategories = useSelector(state => state.categories);
    const userCoords = useSelector(state => (state && state.geo && state.geo.coords) ? state.geo.coords : null);

    const userDistance = useSelector(state => state.search.userDistance);

    const [currentPoint,setCurrentPoint] = useState();

    //&& (point.country === 2 || point.country === 4)
    const getPointData = point_id => pointsData.find(point => point.id === point_id.id );

    //console.log("point variable: ", pointsData[1].category);
    //console.log("point variable: ", pointsData.find(point => (point.country === 1 || point.country === 3)));
    //const tmp42 = getPointData(10);
    //console.log("getPointData: ", tmp42);
    //const pdata=getPointData(19);
    //console.log("test: ", pdata);
    //console.log(pointsData);

    const getPointCoords = function(point_id) {
        //console.log(point_id.id);
        const pointData = pointsData.find(point => point.id === point_id.id);
        //pointData.filter(point=>(point.country === 1 || point.country === 3));
        //console.log("pointData: ",pointData);
        return pointData.latlng;
    }

    const iscountry = function(point) {
        //console.log (point);
        return (point.country === 2 || point.country === 4);
    }

    const getIconFromPoint = point_id => {
        const point_data = getPointData(point_id);

        //console.log("point_data",point_data.category);
        let point_category = pointCategories.find(category => category.id === point_data.category);
        let icon = point_category.icon;

        if (!iconMapping[icon])
            icon = 'default';

        return (point_id === currentPoint) ?
            largeIcons[icon]
            : smallIcons[icon];
    }

    const stitchPointsToPolyline = function() {
        const finalWay = [];

        return finalWay;
    }

    const updateSize = throttle( () => {
        theMap.current.leafletElement.invalidateSize();
    }, 250, { leading: false, trailing: true });

    useEffect(() => {
        if (didMountRef.current && theMap.current) {
            if (theMap.current)
                updateSize();
        }
        else didMountRef.current = true;

        return () => updateSize.cancel();
    });

    // Update polyline
    useEffect(() => {
        setPolylinePositions(stitchPointsToPolyline());
    }, [route_id]);

    const flyToCoords = function(coords) {
        theMap.current.leafletElement.flyTo(coords);
    }

    const [isFlyingTo, setIsFlyingTo] = useState(false);
    const [isZoomingTo, setIsZoomingTo] = useState(false);
    const [zoomlvl, setZoomlvl] = useState(6);
    const [cntr, setCntr] = useState([39.618095, 18.029649]);

    //Detect the zoom level change
    const onViewportChanged = (zoomlvl) => {
        //console.log(zoomlvl);
        setZoomlvl(theMap.current.leafletElement.getZoom());
        };

    const scrollToPoint = function(point_id) {
        const el = document.getElementById(`route_point_${point_id.id}`);

        if (el)
            el.scrollIntoView();
            if(modalCollapsed){
                collapseModal();
            }
    };

    const flyToPoint = function(point_id) {
        if (isFlyingTo)
            return;

        setIsFlyingTo(true);

        setCurrentPoint(point_id);
        flyToCoords(getPointCoords(point_id));

        setTimeout(() => setIsFlyingTo(false), 150);
    }

    const setZoom = function(point_id){
        flyToPoint(point_id);
        setTimeout(() => 500);
        if (isZoomingTo)
            return;

        setIsZoomingTo(true);
        setZoomlvl(12);
        setCntr(getPointCoords(point_id));
        setTimeout(() => setIsZoomingTo(false), 150);
    }

    const checkInView = function(inView, entry, point_id) {
        //console.log('checking in view', {inView, entry, point_id});
        if (inView)
            flyToPoint(point_id);
    }

    const pointClassNames = point_id => {
        return classNames('route-point', { 'current': point_id === currentPoint});
    }

    return <Page title="Points"
                 toolbar={
                     <IonButtons hidden slot='primary'>
                         <IonButton onclick={() => dispatch(toggleFavorited(route_id))}>
                             {
                                 favoriteRoutes.includes(route_id) ?
                                     <IonIcon slot='end' icon={heart} style={{color: 'red'}}/>
                                     : <IonIcon slot='end' icon={heartOutline} color='grey'/>
                             }
                         </IonButton>
                     </IonButtons>
                 }
        >
        <div className='gis-container'>
            <Map ref={theMap}
                 key="resize_1"
                 style={{height: '100%', width: '100%'}}
                 center={cntr} zoom={zoomlvl}
                 onViewportChanged={onViewportChanged}
                 gestureHandling={false}
            >
                <TileLayer
                    url={prefersDark === true ? 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png' : 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png' }
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'/>
                { polylinePositions.length ?
                    <Polyline color="#ff792cb5" opacity={0.5} weight={4} positions={polylinePositions} />
                : ''}
                {pointsData.map(
                    (point_id, point_index) =>
                        iscountry(point_id) ? <>
                        <Marker key={`point_${point_index}`}
                                position={getPointCoords(point_id)}
                                icon={getIconFromPoint(point_id)}
                                onClick={() => {scrollToPoint(point_id); setZoom(point_id);}}
                        /></>:null
                )}
                { userCoords ? <>
                    <Marker key="user" position={[userCoords.latitude, userCoords.longitude]} icon={HereIcon} />
                    { userDistance > 0 ?
                        <Circle
                            center={[userCoords.latitude, userCoords.longitude]}
                            radius={userDistance * 1000}
                            stroke={false}
                            fillColor='#acbd59'
                            fillOpacity={0.2}
                        />
                    :''}
                    </>: null }
            </Map>
            <div className={classNames(['gis-route-modal-container'], {'collapsed': modalCollapsed})}>
                <div className='gis-route-modal' ref={modalRoot}>
                    <div className='collapse' onClick={collapseModal}>
                        <FontAwesomeIcon icon={modalCollapsed ? faAngleUp : faAngleDown} />
                    </div>
                    <div className='route-container'>
                        <div className='route-content'>
                            <div className='route-title'>
                                <FontAwesomeIcon className='route-icon' icon={faMapMarkedAlt} color='#ffffff' />
                                {pointsData[0].title}
                            </div>
                            <div className='route-points'>
                                {
                                    pointsData.map((routePointId, routePointIndex) =>
                                     iscountry(routePointId) ? <>
                                        <div id={`route_point_${routePointId.id}`} className={pointClassNames(routePointId)} key={`route_point_${routePointIndex}`} onClick={() => flyToPoint(routePointId)}>
                                            <div className='route-point-content'>
                                                <InView
                                                    onChange={(inView, entry) => checkInView( inView, entry, routePointId)}
                                                    root={modalRoot.current}
                                                    thresshold={0.2}
                                                >
                                                    <div className='route-point-name'>
                                                        <FontAwesomeIcon className='route-point-icon' icon={faMapMarker} color='#ffffff' />
                                                        {pointsData.find(point => point.id === routePointId.id).name}
                                                    </div>
                                                </InView>
                                                <div className='route-point-description'>{pointsData.find(point => point.id === routePointId.id).description}</div>
                                            </div>
                                        </div>
                                        </>: null
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Page>;
};

export default GISMap;
