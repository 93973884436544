import {createAction, createReducer} from "@reduxjs/toolkit";

const setCategories = createAction('SET_CATEGORIES');

const initialState = [];

const reducer = createReducer(initialState, {
    [setCategories]: (state, action) => action.payload
});

export {setCategories};
export default reducer;