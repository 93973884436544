import React from 'react'
import Page from '../components/Page'
import {IonButton, IonButtons, IonIcon} from "@ionic/react";
//import {toggleFavorited} from "../store/modules/favorites";
import {closeSharp} from "ionicons/icons";
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";

import './Help.scss';

const Help = () => {
    const {t: tPages} = useTranslation('pages');
    const {t: tFaq} = useTranslation('faq');
    const transFaq = useTranslation('faq');

    window.transFaq = transFaq;
    const history = useHistory();

    const MAX_QUESTIONS = 4;

    const questions = [];
    for (let i = 0; i < MAX_QUESTIONS; i++ ) {
        questions.push({
            question: tFaq(`${i}.question`),
            answer: tFaq(`${i}.answer`),
        })
    }

    return <Page title={tPages('help')}
        toolbar={<IonButtons slot='primary'>
            <IonButton onclick={() => history.goBack()}>
               <IonIcon slot='end' icon={closeSharp} style={{color: 'white'}}/>
            </IonButton>
        </IonButtons>}
    >
        <div className='help-section'>{
            questions.map((q, i) => <div key={i}>
                <div className='help-question'>{q.question}</div>
                <div className='help-answer'>{q.answer}</div>
            </div>)
        }</div>
    </Page>
}

export default Help;