import { useState, useEffect } from 'react';

export function type(obj, showFullClass) {

    // get toPrototypeString() of obj (handles all types)
    if (showFullClass && typeof obj === "object") {
        return Object.prototype.toString.call(obj);
    }
    if (obj == null) { return (obj + '').toLowerCase(); } // implicit toString() conversion

    var deepType = Object.prototype.toString.call(obj).slice(8,-1).toLowerCase();
    if (deepType === 'generatorfunction') { return 'function' }

    // Prevent overspecificity (for example, [object HTMLDivElement], etc).
    // Account for functionish Regexp (Android <=2.3), functionish <object> element (Chrome <=57, Firefox <=52), etc.
    // String.prototype.match is universally supported.

    return deepType.match(/^(array|bigint|date|error|function|generator|regexp|symbol)$/) ? deepType :
       (typeof obj === 'object' || typeof obj === 'function') ? 'object' : typeof obj;
  }

export function toggleDark() {
    //setChecked(e);
    //document.body.classList.toggle('dark', e);
    if(document.body.classList.contains('dark'))
    {
        document.body.classList.remove('dark');
        //document.getElementById('fund').classList.remove('dark');
        //document.getElementById('axis').classList.remove('dark');
    }
    else
    {
        document.body.classList.add('dark');
        //document.getElementById('fund').classList.add('dark');
        //document.getElementById('axis').classList.add('dark');
    }
    toggleDarkFund();
    toggleDarkAxis();
    //document.getElementById('fund').classList.toggle('dark', e);
    //console.log("checked: ", prefersDark);
}

export function toggleDarkFund(){
    var elem = document.getElementById('fund');
    if(elem)
    {
        if(document.body.classList.contains('dark'))
        {
            elem.classList.add('dark');
        }
        else
        {
            elem.classList.remove('dark');
        }
    }
}

export function toggleDarkAxis(){
    var elem = document.getElementById('axis');
    if(elem)
    {
        if(document.body.classList.contains('dark'))
        {
            elem.classList.add('dark');
        }
        else
        {
            elem.classList.remove('dark');
        }
    }
}

export function GlobalState(initialValue) {
    this.value = initialValue;  // Actual value of a global state
    this.subscribers = [];     // List of subscribers

    this.getValue = function () {
        // Get the actual value of a global state
        return this.value;
    }

    this.setValue = function (newState) {
        // This is a method for updating a global state

        if (this.getValue() === newState) {
            // No new update
            return
        }

        this.value = newState;  // Update global state value
        this.subscribers.forEach(subscriber => {
            // Notify subscribers that the global state has changed
            subscriber(this.value);
        });
    }

    this.subscribe = function (itemToSubscribe) {
        // This is a function for subscribing to a global state
        if (this.subscribers.indexOf(itemToSubscribe) > -1) {
            // Already subsribed
            return
        }
        // Subscribe a component
        this.subscribers.push(itemToSubscribe);
    }

    this.unsubscribe = function (itemToUnsubscribe) {
        // This is a function for unsubscribing from a global state
        this.subscribers = this.subscribers.filter(
            subscriber => subscriber !== itemToUnsubscribe
        );
    }
}

export function useGlobalState(globalState) {
    const [, setState] = useState();
    const state = globalState.getValue();

    function reRender(newState) {
        // This will be called when the global state changes
        setState({});
    }

    useEffect(() => {
        // Subscribe to a global state when a component mounts
        globalState.subscribe(reRender);

        return () => {
            // Unsubscribe from a global state when a component unmounts
            globalState.unsubscribe(reRender);
        }
    })

    /*function setState(newState) {
        // Send update request to the global state and let it 
        // update itself
        globalState.setValue(newState);
    }*/
    //setState(newState);

    return [state, setState];
}
