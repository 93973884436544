import React, {useEffect} from 'react';

import './Search.scss'

import Page from "../components/Page";
import {
    IonButton,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader, IonRange,
    IonSelect,
    IonSelectOption, IonToggle
} from "@ionic/react";
import {useDispatch, useSelector} from "react-redux";
import {uniq} from "lodash";
import {setSearchData, setSearchProp} from "../store/modules/search";
import {useHistory} from "react-router";
import {calculateMatchingPoints} from "../services/search";
import {useTranslation} from "react-i18next";

var temp=window.location.href.split('/')[3];

if(temp!="")
{
window.onbeforeunload = function () {
    
        window.location.assign(window.location.href.substring(0,(window.location.href.length-temp.length)));
        //window.location.href=window.location.href.substring(0,(window.location.href.length-temp.length));
    }
}

const toggleColor = 'purple';

const Search = (props) => {
    const history = useHistory();
    const {t} = useTranslation('search');

    const dispatch = useDispatch();

    const locale = useSelector(state => state.settings.locale);

    useEffect(() => {
    }, [locale]);

    const pointsData = useSelector(state => state.points);
    const getPoint = point_id => pointsData.find(point => point.id === point_id);

    /* COUNTRIES */
    const allCountries = useSelector(state => state.countries);
    const setCountries = countries => {
        dispatch(setSearchProp({countries: countries}));
        dispatch(setSearchProp({regions: []}));
        //dispatch(setSearchProp({routecategories: []}));
        dispatch(setSearchProp({categories: []}));
    };

    //setCountries(allCountries.map(_ => _.id));

    /* FILTERED ROUTES */
    const availableRoutes = useSelector(state => state.routes.filter(route => state.search.countries.includes(route.country)));

    /* FILTERED POINTS */
    const availablePoints = useSelector(state => {
        let point_ids = [];

        availableRoutes.map(route => point_ids = point_ids.concat(route.points));
        return uniq(point_ids).map(
            point_id => state.points.find(point => point.id === point_id)
        );
    });
    /* REGIONS */
    const getRegions = points => uniq(
        points.map(item => item.region)
            .filter(item => item !== '' && item != null)
    );
    const setRegions = regions => {
        dispatch(setSearchProp({regions}));
        //dispatch(setSearchProp({routecategories: []}));
        dispatch(setSearchProp({categories: []}));
    };

    //const allRegions = useSelector(state => getRegions(state.points));
    const availableRegions = useSelector(state => getRegions(availablePoints));

    /* POINT CATEGORIES */
    const allPointCategories = useSelector(state => state.categories);

    const availablePointCategories = useSelector(state => {
        const points = [];
        const pointCategories = [];
        availableRoutes.map(route =>
            route.points.map(point =>
                points.push(point)
            )
        );

        points.map(_ => pointCategories.push(getPoint(_).category));

        return uniq(pointCategories);
    });

    const getPointCategoryFromId = categoryId => {
        return allPointCategories.find(_ => _.id === categoryId);
    }

    // Search Options
    const searchInitialized = useSelector(state => state.search.initialized);
    const countries = useSelector(state => state.search.countries);
    const regions = useSelector(state => state.search.regions);
    const selectedPointCategories = useSelector(state => state.search.categories);
    const accessibilityOptions = useSelector(state => state.search.accessibilityOptions);
    const childrenFriendlyOptions = useSelector(state => state.search.childrenFriendlyOptions);
    const userDistance = useSelector(state => state.search.userDistance) || 0;
    const userDistanceOn = useSelector(state => state.search.userDistanceOn) || false;

    //const userGeo = useSelector(state => (state && state.geo && state.geo.coords));
    const userGeo = useSelector(state => (state && state.geo && state.geo.latitude && state.geo.longitude));

    const resetSearch = function() {
        dispatch(setSearchData({
            initialized: true,
            categories: [],
            countries: countries,
            regions: availableRegions,
            startingPoints: [],
            endingPoints: [],
            includedPoints: [],
            accessibilityOptions: false,
            childrenFriendlyOptions: false,
            userDistance: 10,
            userDistanceOn: false
        }));
    }
    //console.log(window.location.href.split('/')[3]);
    //var temp=window.location.href.split('/')[3];
    //console.log(window.location.href.slice(-1,-5));
    //console.log(window.location.href.substring(0,(window.location.href.length-temp.length)));

    /*window.onbeforeunload = function () {

        return 'Are you sure you want to leave?';
     }*/

    //console.log('regions: ',regions);

    useEffect(() => {
        if (!searchInitialized)
            resetSearch();
        setCountries(allCountries.map(_ => _.id));
        setRegions(availableRegions);
        dispatch(setSearchProp({categories: availablePointCategories}));
    },[searchInitialized])

    const filteredRoutes = useSelector(state => searchInitialized ? calculateMatchingPoints() : []);

    const renderPage = () =>
        <Page title={t('title')} className='search_form'>
            <IonList>
                <IonListHeader>{ t('sections.areas') }</IonListHeader>
                <IonItem>
                    <IonSelect
                        slot='end'
                        multiple={true}
                        value={countries}
                        onIonChange={(e) => {
                            setCountries(e.detail.value)
                            setRegions(regions)
                            dispatch(setSearchProp({categories: availablePointCategories}))
                        }}
                        placeholder={t('placeholder')}
                        interfaceOptions={
                            {
                                header: t('sections.areas'),
                            }
                        }                        
                    >
                        { allCountries.map( _ =>
                            <IonSelectOption key={`country_${_.id}`} value={_.id}>{_.name}</IonSelectOption>
                        ) }
                    </IonSelect>
                    <IonButton slot='start'
                           disabled={countries.length === allCountries.length}
                           onClick={
                               () => setCountries(allCountries.map(_ => _.id))
                           }>
                        { t('all') }
                    </IonButton>
                </IonItem>

                <IonListHeader>{ t('sections.regions') }</IonListHeader>
                <IonItem>
                    <IonSelect
                        slot='end'
                        multiple={true}
                        value={regions}
                        onIonChange={(e) => {
                            setRegions(e.detail.value)
                            dispatch(setSearchProp({categories: availablePointCategories}))
                        }}
                        placeholder={t('placeholder')}
                        interfaceOptions={
                            {header: t('sections.regions')}
                        }
                    >
                        { availableRegions.map( _ =>
                            <IonSelectOption key={`region_${_}`} value={_}>{_}</IonSelectOption>
                        ) }
                    </IonSelect>
                    <IonButton id="regelem" slot='start'
                           disabled={availableRegions.length === regions.length}
                           onClick={() => setRegions(availableRegions)}
                    >{ t('all') }</IonButton>
                </IonItem>

                <IonListHeader>{ t('sections.point_categories') }</IonListHeader>
                <IonItem>
                    <IonSelect
                        slot='end'
                        multiple={true}
                        value={selectedPointCategories}
                        onIonChange={e => dispatch(setSearchProp({categories: e.detail.value}))}
                        placeholder={t('placeholder')}
                        interfaceOptions={
                            {header: t('sections.point_categories')}
                        }
                    >
                        { availablePointCategories.map( categoryId =>
                            <IonSelectOption key={`category_${categoryId}`} value={categoryId}>{getPointCategoryFromId(categoryId).name}</IonSelectOption>
                        ) }
                    </IonSelect>
                    <IonButton
                        slot='start'
                        disabled={availablePointCategories.length === selectedPointCategories.length}
                        onClick={() => dispatch(setSearchProp({categories: availablePointCategories}))}
                    >{ t('all') }</IonButton>

                </IonItem>

                {/* userGeo ?
                    <>
                        <IonListHeader style={{display: 'none'}}></IonListHeader>
                        <IonItem>
                            <IonLabel>{ t('sections.user_distance_toggle') }</IonLabel>
                            <IonToggle
                                color={toggleColor}
                                checked={userDistanceOn}
                                onIonChange={e => dispatch(setSearchProp({userDistanceOn: e.detail.checked}))}>
                            </IonToggle>
                        </IonItem>
                    </>
                        : ''*/}
                {/* userGeo && userDistanceOn ?
                    <>
                        <IonListHeader>{t('sections.user_distance')}</IonListHeader>
                            <IonItem>
                            <span slot='start'>{userDistance} km</span>
                            <IonRange value={userDistance} min={10} max={1500} step={20} snaps={true}
                                      onIonChange={e => dispatch(setSearchProp({userDistance: e.detail.value}))}
                            />
                        </IonItem>
                    </>
                    : ''*/}

                <IonItem>
                    <IonLabel>{ t('sections.accessibility') }</IonLabel>
                    <IonToggle
                        color={toggleColor}
                        checked={accessibilityOptions}
                        onIonChange={e => dispatch(setSearchProp({accessibilityOptions: e.detail.checked}))}>
                    </IonToggle>
                </IonItem>

                <IonItem>
                    <IonLabel>{ t('sections.child_friendly') }</IonLabel>
                    <IonToggle
                        color={toggleColor}
                        checked={childrenFriendlyOptions}
                        onIonChange={e => dispatch(setSearchProp({childrenFriendlyOptions: e.detail.checked}))}>
                    </IonToggle>
                </IonItem>
            </IonList>

            <div className='ion-text-center ion-margin-top ion-margin-bottom'>
                <IonButton className='search_results' onClick={() => history.push('/results')}>{ t('results', {num: filteredRoutes.length} ) }</IonButton>
            </div>
        </Page>;

    return searchInitialized ? renderPage() : '';
};


export default Search;