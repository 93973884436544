import store from '../store'
import {some, last, intersection, minBy, find, sortBy, orderBy, uniq} from "lodash";
//import {getDistance} from "./geo";
import {filter} from "ionicons/icons";

/*const getGeoCoords = function(state) {
    const geo = state.geo;

    // returns false if not available
    return geo && geo.coords;
}*/

/*const filterRoutesByProximity = function(state, matchedRoutes, distance) {
    const points = state.points;

    const getPointsDistances = points => {
        return points.map(point => (
            {
                ...point,
                distance: getDistance(point.latlng)
            }
        ));
    }

    matchedRoutes = matchedRoutes.map(route => {
        const routePoints = route.points.map(id => find(points, {id}));
        const pointsWithDistances = getPointsDistances(routePoints);
        const closestPointDistance = minBy(pointsWithDistances, 'distance').distance;

        return {
            ...route,
            closestPointDistance
        }
    });
    if (distance)
        matchedRoutes = matchedRoutes.filter(route => route.closestPointDistance <= distance);

    return sortBy(matchedRoutes, 'closestPointDistance');
}*/

const calculateMatchingRoutes = function() {
    const state = store.getState();
    const routes = state.routes;
    const points = state.points;
    const routecategories = state.routecategories;
    const searchData = state.search;
    const geo = state.geo;

    let matchedRoutes = routes;

    // Countries
    matchedRoutes = matchedRoutes.filter(
        route => searchData.countries.includes(route.country)
    );

    // Areas
    matchedRoutes = matchedRoutes.filter(route => {
        const routePoints = points.filter(point => route.points.includes(point.id));
        const routeAreas = routePoints.map(point => point.region);

        return intersection(searchData.regions, routeAreas).length;
    })
    
    // Route Categories
    matchedRoutes = matchedRoutes.filter( route => {
        if (!intersection(
            route.categories.map(_ => _.id),
            searchData.routecategories
        ).length)
            console.log({id: route.id, selected: searchData.routecategories, categories: route.categories.map(_ => _.id)});

        return intersection(
            route.categories.map(_ => _.id),
            searchData.routecategories
        ).length
    });
    
    // Distance
    matchedRoutes = matchedRoutes.filter( route => {
        if (route.distance === 0)
             return true;
        return (route.distance >= searchData.tripLength.lower && route.distance <= searchData.tripLength.upper);
    });
    
    // Time
    matchedRoutes = matchedRoutes.filter( route => {
        let time = Math.max(1, route.time / 60);

        return  (time >= searchData.tripTime.lower && time <= searchData.tripTime.upper)
    });
    
    // Point Categories
    matchedRoutes = matchedRoutes.filter(route => {
        const routePoints = points.filter(point => route.points.includes(point.id));
        const routePointCategories = uniq(routePoints.map(point => point.category));

        //console.log(intersection(routePointCategories, searchData.categories).length);
        return intersection(routePointCategories, searchData.categories).length;
    });
    
    // Included Points
    //console.log({includedPoints: searchData.includedPoints})
    if (searchData.includedPoints.length)
        matchedRoutes = matchedRoutes.filter(route => {
            return intersection(searchData.includedPoints, route.points).length;
        })
    
    // User Distance
    /*if (searchData.userDistanceOn && getGeoCoords(state))
        matchedRoutes = filterRoutesByProximity(state, matchedRoutes, searchData.userDistance);*/
    
    if (searchData.accessibilityOptions)
        matchedRoutes = matchedRoutes.filter(route => route.accessibility);
    
    if (searchData.childrenFriendlyOptions)
        matchedRoutes = matchedRoutes.filter(route => route.child_friendly);
    
    return matchedRoutes;
}

/*const filterPointsByProximity = function(state, matchedPoints, distance) {
    const points = [state.points];

    console.log("state.points",state.points);
    const getPointsDistances = points => {
        return points.map(point => (
            {
                ...point,
                //point,
                distance: getDistance(point.latlng)
            }
        ));
    }
    matchedPoints = getPointsDistances(points);
    //console.log("getPointsDistances",getPointsDistances);
    /*matchedPoints = matchedPoints.map(point => {
        //const routePoints = route.points.map(id => find(points, {id}));
        const pointsWithDistances = getPointsDistances(routePoints);
        const closestPointDistance = minBy(pointsWithDistances, 'distance').distance;

        return {
            ...route,
            closestPointDistance
        }
    });*/
    /*if (distance)
        matchedPoints = matchedPoints.map(point => point.closestPointDistance <= distance);

    return sortBy(matchedPoints, 'closestPointDistance');
}*/

const calculateMatchingPoints = function() {
    const state = store.getState();
    const routes = state.routes;
    const points = state.points;
    const routecategories = state.routecategories;
    const searchData = state.search;
    const geo = state.geo;

    let matchedPoints = points;
    let matchedRoutes = routes;

    // Countries
    matchedPoints = matchedPoints.filter(
        point => searchData.countries.includes(point.country)
    );

    // Areas
    matchedPoints = matchedPoints.filter(point => {
        //const routePoints = points.filter(point => route.points.includes(point.id));
        //const routeAreas = searchData.regions[0].includes(point.region);//searchData.countries.includes(point.region);//routePoints.map(point => point.region);
        const routeAreas = searchData.regions.map(region => region.includes(point.region) ? point.region : null) ;
        return intersection(searchData.regions, routeAreas).length;
    })
    
    // Route Categories
    /*matchedPoints = matchedPoints.filter( route => {
        if (!intersection(
            route.categories.map(_ => _.id),
            searchData.routecategories
        ).length)
            console.log({id: route.id, selected: searchData.routecategories, categories: route.categories.map(_ => _.id)});

        return intersection(
            route.categories.map(_ => _.id),
            searchData.routecategories
        ).length
    });*/
    
    // Distance
    /*matchedRoutes = matchedRoutes.filter( route => {
        if (route.distance === 0)
             return true;
        return (route.distance >= searchData.tripLength.lower && route.distance <= searchData.tripLength.upper);
    });*/
    
    // Time
    /*matchedRoutes = matchedRoutes.filter( route => {
        let time = Math.max(1, route.time / 60);

        return  (time >= searchData.tripTime.lower && time <= searchData.tripTime.upper)
    });*/
    
    // Point Categories
    matchedPoints = matchedPoints.filter(point => {
        //const routePoints = points.filter(point => route.points.includes(point.id));
        //const routePointCategories = uniq(points.map(point => point.category));
        const routePointCategories = Array.from([point.category]);//uniq(point.map(point => point.category));

        //console.log(intersection(routePointCategories, searchData.categories).length);
        //console.log('testing categories: ',routePointCategories);
        //console.log('searchData.categories: ',searchData.categories);
        return intersection(routePointCategories, searchData.categories).length;
    });

    // Included Points
    //console.log({includedPoints: searchData.includedPoints})
    if (searchData.includedPoints.length)
        matchedPoints = matchedPoints.filter(point => {
            return intersection(searchData.includedPoints, Array.from([point.id])).length;
        })
    
    // User Distance
    //if (searchData.userDistanceOn && getGeoCoords(state))
    //matchedPoints = filterPointsByProximity(state, matchedPoints, searchData.userDistance);
    
    //console.log("testing accessibility: ",routes[27].points.includes(matchedPoints[0].id));

    
    //routes.map(route => route.points.includes(point.id))
    /*const unique = (mid) => {
        var temp=[];
        var j;
        var i;
        temp[0]=mid[0];
        for(i=1;i<mid.length;i++){
            for(j=0;j<temp.length;j++){
                if(mid[i].name == temp[j].name)
                    break;
            }
            if(j==temp.length){
                temp[j]=mid[i];
            }
        }
        return temp;
    }*/
    function unique(value, index, self) 
    {
        return self.indexOf(value) === index;
    }
    const getacc = () =>{
        let acc = routes.filter(route => route.accessibility === 1);
        let acc2 = acc.map(route => route.points);
        let acctemp = [];
        let j=0;
        for(var i=0;i<acc2.length;i++)
        {
            for(var k=0;k<acc2[i].length;k++)
            {
                acctemp[j] = acc2[i][k];
                j++;
            }
        }
        let acc3 = acctemp.filter(unique);
        return acc3;
    }
    const getcf = () =>{
        let cf = routes.filter(route => route.child_friendly === 1);
        let cf2 = cf.map(route => route.points);
        let cftemp = [];
        let j=0;
        for(var i=0;i<cf2.length;i++)
        {
            for(var k=0;k<cf2[i].length;k++)
            {
                cftemp[j] = cf2[i][k];
                j++;
            }
        }
        let cf3 = cftemp.filter(unique);
        /*console.log("cf: ",cf);
        console.log("cf2: ",cf2);
        console.log("cftemp: ",cftemp);
        console.log("cf3: ",cf3);*/
        return cf3;
    }
    const acc = getacc();
    const cf = getcf();
    
    //console.log("acc: ",acc);
    //console.log("cf: ",cf);

    if (searchData.accessibilityOptions)
    //matchedPoints = matchedPoints.filter(point => routes[27].points.includes(point.id));
    matchedPoints = matchedPoints.filter(point => acc.includes(point.id));
    
    //console.log("testing accessibility: ",routes.filter(route => route.child_friendly === 1));

    if (searchData.childrenFriendlyOptions)
    //matchedPoints = matchedPoints.filter(point => routes[27].points.includes(point.id));
    matchedPoints = matchedPoints.filter(point => cf.includes(point.id));
    
    return matchedPoints;
}

export {calculateMatchingPoints, calculateMatchingRoutes}//filterRoutesByProximity, filterPointsByProximity, getGeoCoords
