import React, {useEffect, useState} from 'react';

import './Settings.scss'

import Page from "../components/Page";
import {
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonSelect,
    IonSelectOption, IonToggle
} from "@ionic/react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Flags from "country-flag-icons/react/1x1";
import {cycleLocale} from "../i18n";
import {toggleDark, toggleDarkAxis, toggleDarkFund} from "../scripts/helpingFunctions.js";

const toggleColor = 'purple';

const Settings = (props) => {
    const {t} = useTranslation('settings');

    const locale = useSelector(state => state.settings.locale);

    useEffect(() => {
    }, [locale]);

    const getFlag = lang => {
        const style = {width: '2em', height: '2em'};
        switch (lang) {
            case 'it': return <Flags.IT slot='end' style={style} />;
            case 'en': return <Flags.GB slot='end' style={style} />;
            default : return '';
        }

        //return '';
    };

    const [prefersDark, setChecked] = useState(false);

    useEffect(() => {
        toggleDarkAxis();
        toggleDarkFund();
        if(document.body.classList.length>0)
        {
            setChecked(true);
        }
        else
        {
            setChecked(false);
        }
    }, []);

    var locales = [t('locales.english'),t('locales.italian')];
    var lacaleKeys = ['en', 'it'];

    //console.log("locale: ",locale);
    const [value, setValue] = useState(locale);

    const renderPage = () =>
        <Page title={t('title')} className='search_form'>
            <IonList>
                <IonListHeader className='headerSetting'>{ t('language') }</IonListHeader>
                <IonItem>
                    <IonSelect
                        slot='start'
                        multiple={false}
                        value={value}
                        placeholder={t('placeholder')}
                        interfaceOptions={
                            {
                                header: t('language'),
                            }
                        }
                        onIonChange={(e) => {setValue(e.detail.value); cycleLocale()}}
                        defaultChecked={true}
                    >
                        { locales.map( (locales, i) =>
                            <IonSelectOption key={lacaleKeys[i]} value={lacaleKeys[i]}>{locales}</IonSelectOption>
                        ) }
                    </IonSelect>
                    {getFlag(locale)}
                </IonItem>

                <IonListHeader className='headerSetting'>{ t('appearance') }</IonListHeader>
                <IonItem>
                    <IonLabel>{ t('dark') }</IonLabel>
                    <IonToggle
                        color={toggleColor}
                        checked={prefersDark}
                        //onIonChange={e => dispatch(setSearchProp({accessibilityOptions: e.detail.checked}))}
                        onClick={() => {toggleDark()}}
                        id="themeToggle"
                        >
                    </IonToggle>
                </IonItem>

            </IonList>

        </Page>;

    return renderPage();
};


export default Settings;