import React from 'react';
import {useSelector} from "react-redux";
//import {IonIcon, IonItem, IonLabel, IonList} from "@ionic/react";
import {IonItem, IonLabel, IonList} from "@ionic/react";
//import {heart, heartCircle, heartOutline} from "ionicons/icons";
//import {toggleFavorited} from "../store/modules/favorites";
import {useHistory} from "react-router";


const RouteList = (props, context) => {
    const history = useHistory();

    const mid = useSelector(state => {
        if (props.points)
            return props.points;

        return state.points;
    });

    const gtpts = () => {
        var temp=[];
        var j;
        var i;
        temp[0]=mid[0];
        for(i=1;i<mid.length;i++){
            for(j=0;j<temp.length;j++){
                if(mid[i].name === temp[j].name)
                    break;
            }
            if(j===temp.length){
                temp[j]=mid[i];
            }
        }
        return temp;
    }
    const points = gtpts();

    /*const makeRouteSubtitle = route => {
        return <>{
            favoriteRoutes.includes(route.id) ?
                <IonIcon icon={heart} style={{color: 'red'}}
                         onClick={() => dispatch(toggleFavorited(route.id))}/>
                : <IonIcon icon={heartOutline} color='grey'
                           onClick={() => dispatch(toggleFavorited(route.id))}/>
            }<span className='categories'>{ getPointCategoryNames(route.id) }</span>
            {route.closestPointDistance ?
                <span className='distance'>{route.closestPointDistance.toFixed(1)} km</span>
                : ''
            }
        </>
    }*/

    return <><IonList className='routes-list'>
            {points.map( route =>
                <IonItem key={route.id}>
                    <IonLabel className='title' onClick={() =>{
                            history.push(`/gis/routes/${route.id}`);
                        }}>
                    {route.name}
                    </IonLabel>
                    {/*<IonLabel position='stacked' className='subtitle'>
                        { makeRouteSubtitle(route) }
                    </IonLabel>*/}
                </IonItem>
            )}
        </IonList>
        </>;
}

export default RouteList;