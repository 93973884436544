export default {
    pages: {
        'Search Results': 'Selected Points of Interest',
        'All Routes': 'All Points of Interest',//'All Thematic Routes',
        'Closest Routes': 'Points of Interest close to me',
        help: 'Help'
    },
    welcome : {
        //intro : 'The PORTOLANES i-Tourist Guide is an intelligent mobile app (i-Tourist Guide) for trip planning within the PORTOLANES Thematic Routes (ports and inland) based on user-defined criteria.',
        intro: '“Portolanes – Sustainable Development of Nautical Tourism and Enhancement of Small Ports of the Ionian Sea” aims to increase the nautical tourism that is an industry of high added value with strong growth trend in the Adriatic and Ionian Seas. The main objective is the preservation of marine and coastal environment that includes the natural resources which potentially interact with targeted nautical tourism activities and the protection of inland’s natural and cultural assets which are foreseen to be exploited by the cross-border tourism.',
        action: 'Select Points of Interest',
        'This project is co-financed by the European Union under the Instrument for Pre-Accession Assistance (IPA II)': 'This application is co-funded by European Union, European Regional Development Funds (E.R.D.F.) and by National Funds of Greece and Italy.',
        //'This project is co-financed by the European Union under the Instrument for Pre-Accession Assistance (IPA II)': 'This Project is co-financed by the European Union under the Instrument for Pre-Accession Assistance (IPA II)',
        all_routes: 'All Points of Interest',
        help: 'Help',
        manual: 'User Manual',
        proximity: 'Points close to me'
    },
    gis: {
        trip_time: 'Total Trip Duration',
        trip_length: 'Total Distance to be Travelled',
        hours: 'Hours'
    },
    search: {
        title: 'Selection Criteria',
        Yes: 'Yes',
        No: 'No',
        any: 'Any',
        all: 'Select All',
        placeholder: 'Select',
        results: 'Display selected Points of Interest ({{num}})',
        sections: {
            categories: 'Categories of Points of Interest',
            point_categories: 'Categories of Points of Interest',
            countries: 'Countries',
            regions: 'Specific Areas to be Travelled (Regions)',
            starting_areas: 'Starting Areas',
            ending_areas: 'Ending Areas',
            accessibility: 'Accessibility for the Elderly and Handicapped',
            child_friendly: 'Child Friendly',
            trip_length: 'Length Range of Thematic Routes',
            trip_time: 'Trip Time Range of Thematic Routes',
            toggle_points: 'Thematic Routes with the selected Points of Interest',
            points: 'Points of Interest',
            areas: 'General Areas to be Travelled',
            user_distance: 'Distance from the closest Point of Interest',
            user_distance_toggle: 'Only Points close to me'
        },
        categories: {
            Activity:'Activity',
            Church: 'Church',
            Culture: 'Culture',
            Fortress: 'Fortress',
            Gastronomy: 'Gastronomy',
            Hotel: 'Hotel',
            Isole: 'Isole',
            Marina: 'Marina',
            Museum: 'Museo',
            Nature: 'Natura',
            'Nature Reserve': 'Nature Reserve',
            Park: 'Park',
            Port: 'Port',
            River: 'River',
            Lake: 'Lake',
            Seaside: 'Seaside',
            Town: 'Town',
            Villages: 'Villages',
            'Molisian Croatian': 'Molisian Croatian'
        },
        days: 'days',
        hours: 'hours'
    },
    settings: {
        title: 'Settings',
        language: 'Change Language',
        appearance: 'Choose Appearance',
        locales: {
            english: 'English',
            italian: 'Italiano'
        },
        dark: 'Dark Mode'
    },
    listing: {
        geolocation_not_available: 'Geolocation is not available.\n' + 'Please check your GPS settings',
        no_results: 'No Results'
    },
    /*faq: [
        {
            question: 'What is the PORTOLANES Project?',
            answer: `The PORTOLANES Project “Sustainable Development of Nautical Tourism and Enhancement of Small Ports of the Ionian Sea” is part of the Interreg Greece-Italy.
The PORTOLANES project seeks to exploit the high tourism potential of the intervention area, through the development of Nautical tourism in 21 small and medium-sized ports (12 in Italy, 4 in Albania and 5 in Montenegro).
It aims to link the selected ports with alternative tourism activities related to inland’s natural and cultural assets, including areas of high aesthetical and ecological value, sports and leisure activities, archaeological sites and historical monuments, as well as multiple thematic destinations focused on gastronomy, religion and culture.
The Project will develop a common integrated tourism development plan encompassing attractive tourism packages, through a sustainable approach with respect to the natural and anthropogenic environment by upgrading the services through the utilization of new technologies which are expected to enhance tourism share in the selected areas.
The Project targets the strong clientele of Nautical tourism; however, the overall PORTOLANES approach is not limited only to Nautical tourism activities but further includes thematic destinations linked with inland’s natural and cultural assets.
The overall objective of the Project is to develop a common model for the smart and sustainable NT management through integrated development plans, considering as a top priority the environmental sustainability.
PORTOLANES aims to provide a holistic approach by:
 - connecting the coastal areas with the rich inland’s assets,
 - emerging and promoting the interconnected areas (Area Brand, Thematic Routes), and
 - upgrading tourism supporting services and products.
The plan will constitute an innovative approach in the tourism industry, which cannot be successfully tackled by individual national strategies. Its anticipated impact will substantially benefit stakeholders in the intervention area, including local communities, national and/or regional organizations and tourism related businesses.
The main expected Outputs include:
 - Operational CBC NT Development Plana
 - Pollution Monitoring Installations
 - Operational Platform with Common Tourism Services
 - Establishment of PORTOLANES Centers
 - Tools for Upgrading Tourism Skills.
The results are anticipated to aid smart and sustainable tourism management, thus increase tourism population, boost financial growth and reduce unemployment at local/regional scale. The above results will be achieved without jeopardizing environmental sustainability. PORTOLANES will increase the use of ICT technologies and enhance the “low-tech” profile of the Cross Border region, support the intervention area in successfully competing with other Mediterranean tourist destinations and, ultimately interconnect coastal with inland areas and maximize their potential.
`
        },
        {
            question: 'What is the i-Tourist Guide App?',
            answer: 'The i-Tourist Guide is an intelligent mobile app for trip planning within the PORTOLANES Thematic Routes (ports and inland) based on user-defined criteria.'
        },
        {
            question: 'For which countries does the i-Tourist Guide provide information for?',
            answer: 'The i-Tourist Guide provides information for all three of the participating countries in the PORTOLANES Project (Italia, Albania, Montenegro)'
        },
        {
            question: 'Can I select a category of Thematic Routes?',
            answer: 'Yes. You are able to select a Category of Thematic Routes such as Archeological, Natural, Coastal and many others.'
        },
        {
            question: 'Can I select a category of Points of Interest?',
            answer: 'Yes. You are able to select a category of Points of Interest such as Port, Natura, Town and many others.'
        },
        {
            question: 'Can I select a specific Point of Interest?',
            answer: 'Yes. You are able to select a specific Point of Interest of the selected category of Points of Interest.'
        },
        {
            question: 'Can I put any limits on the duration or length of the Thematic Route?',
            answer: 'Yes. You are able to select the length of the trip (0-300 km) and the total trip time (0 – 24 hours).'
        },
        {
            question: 'Are any accessibility features taken into account?',
            answer: 'Yes. The i-Tourist Guide takes into account accessibility for the Elderly, for Handicapped people and whether or not the Thematic Routes are Child Friendly.'
        },
        {
            question: 'Can I see how many Thematic Routes fits to my choices?',
            answer: 'Yes. A button displays the number of the Thematic Routes selected according to your choices.'
        },
        {
            question: 'Can I find more details about the selected Thematic Routes?',
            answer: 'Yes. All the selected Thematic Routes may be presented in the GIS, where you can find information for each Point of Interest.'
        },
        {
            question: 'If GPS is enabled on my device will it show Thematic Routes close to me?',
            answer: 'Yes. The i-Tourist Guide detects your location and display Thematic Routes which include Points of Interest up to 50 km away from you.'
        }
    ]*/
    faq: [
        {
            question: 'What is the PORTOLANES Proejct?',
            answer: `“Portolanes – Sustainable Development of Nautical Tourism and Enhancement of Small Ports of the Ionian Sea” aims to increase the nautical
             tourism that is an industry of high added value with strong growth trend in the Adriatic and Ionian Seas. In general, nautical tourism is more
              popular during summertime, but if it is properly combined with other tourism activities it will have a positive impact for local economies and for regional economic growth.`
        },
        {
            question: 'What is the main objective of the PORTOLANES Project?',
            answer: 'The main objective is the preservation of marine and coastal environment that includes the natural resources which potentially interact with targeted nautical tourism activities and the protection of inland’s natural and cultural assets which are foreseen to be exploited by the cross-border tourism.'
        },
        {
            question: 'Who is the Lead Partner of the PORTOLANES Project?',
            answer: 'Regional Union of the Provinces of Puglia-UPI PUGLIA is the Lead Partner of the project.'
        },
        {
            question: 'Who are the partners of the PORTOLANES Project?',
            answer: 'The Italian partner is Confcommercio Puglia, while the three Greek partners are the Ministry of Tourism, Regional Union of Municipalities of Epirus and Network Of European Cities For Sustainable Development.'
        }
    ]
}