import React from 'react'
import {useSelector} from "react-redux";
//import {calculateMatchingRoutes} from "../services/search";
import RouteList from "./RouteList";
import Page from "../components/Page";
import {useTranslation} from "react-i18next";

const Results = (props, context) => {
    const favoriteRoutes = useSelector(state => state.favorites);
    const routesData = useSelector(state => state.routes.filter(route => favoriteRoutes.includes(route.id)));

    const {t} = useTranslation('pages');

    return <Page title={t('Favorites')}>
        <RouteList routes={routesData} />
    </Page>;
}

export default Results;