import {createAction, createReducer} from "@reduxjs/toolkit";

const setWays = createAction('SET_WAYS');

const initialState = [];

const reducer = createReducer(initialState, {
    [setWays]: (state, action) => action.payload
});

export {setWays};
export default reducer;