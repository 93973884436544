import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react'
import App from './App';
import {default as store, persistor} from './store/index'
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import {IonReactRouter} from "@ionic/react-router";

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor} loading={null} onBeforeLift={function() {}}>
            <IonReactRouter>
                <App />
            </IonReactRouter>
        </PersistGate>
    </Provider>
    , document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
