export default {
    pages: {
        'Favorites': 'Preferiti',
        'Search Results': 'Punti di interesse selezionati',
        'All Routes': 'Tutti i punti di interesse',
        'Closest Routes': 'Punti di interesse vicino a me',
        help: 'Aiuto'
    },
    welcome : {
        'This project is co-financed by the European Union under the Instrument for Pre-Accession Assistance (IPA II)': 'Questa applicazione è cofinanziata dall\' Unione Europea, da Fondi Europei di Sviluppo Regionale (E.R.D.F.) e da Fondi Nazionali di Grecia e Italia.',
        all_routes: 'Tutti i Punti di interesse',
        action: 'Seleziona Punti di interesse',
        help: 'Aiuto',
        manual: 'Manuale Utente',
        //intro : 'La PORTOLANES i-Tourist Guide  è un\'app mobile intelligente per la pianificazione dei viaggi dei Percorsi Itinerari Tematici PORTOLANES (porti e entroterra) in base a criteri definiti dall\'utente. ',
        intro: '“Portolanes – Sviluppo Sostenibile del Turismo Nautico e Valorizzazione dei Piccoli Porti dello Ionio” mira ad accrescere il turismo nautico che rappresenta un\'industria ad alto valore con forte trend di crescita nel Mar Adriatico e Ionio. L\'obiettivo principale è la conservazione dell\'ambiente marino e costiero il quale comprende le risorse naturali che potenzialmente interagiscono con attività di turismo nautico con scopo la protezione dei beni naturali e culturali dell\'entroterra previsti di essere sfruttati dal turismo transfrontaliero.',
        proximity: 'Punti di interesse nelle vicinanze'
    },
    gis: {
        trip_time: 'Durata totale del viaggio',
        trip_length: 'Distanza totale da percorrere',
        hours: 'Ore'
    },
    search: {
        title: 'Criteri di Selezione',
        Yes: 'Si',
        No: 'No',
        any: 'Qualsiasi',
        all: 'Seleziona Tutto',
        placeholder: 'Selezionare',
        results: 'Visualizzare gli Itinerari Tematici selezionati ({{num}})',
        sections: {
            categories: 'Categorie di Punti di Interesse',
            point_categories: 'Categorie di punti di interesse',
            countries: 'Paesi',
            regions: 'Aree specifiche da percorrere (Regioni)',
            starting_areas: 'Aree di Partenza',
            ending_areas: 'Aree di Termine',
            accessibility: 'Accessibilità per Anziani e Portatori di Handicap',
            child_friendly: ' Adatto ai Bambini',
            trip_length: 'Distanza dal punto di interesse più vicino',
            trip_time: 'Durata del viaggio degli itinerari tematici',
            toggle_points: 'Percorsi tematici con i punti di interesse scelti',
            points: 'Punti di interesse',
            areas: 'Aree generali da percorrere',
            user_distance_toggle: 'Solo percorsi vicino a me'
        },
        categories: {
            Activity:'Attività',
            Church: 'Chiese',
            Culture: 'Cultura',
            Fortress:	'Fortezza',
            Gastronomy:	'Gastronomia',
            Hotel:	'Hotel',
            Isole:	'Isole',
            Marina: 'Marina',
            Museum:	'Museo',
            Nature:	'Natura',
            'Nature Reserve':	'Riserva naturale',
            Park:	'Parco',
            Port:	'Porto',
            River:	'Fiumi',
            Lake:	'Lagi',
            Seaside:	'Mare',
            Town:	'Citta',
            Villages: 'Paesi',
            'Molisian Croatian': 'Croati del Molise'
        },
        days: 'giorni',
        hours: 'ore'
    },
    settings: {
        title: 'Impostazioni',
        language: 'Cambia lingua',
        appearance: 'Scegli Aspetto',
        locales: {
            english: 'English',
            italian: 'Italiano'
        },
        dark: 'Tema Scuro'
    },
    listing: {
        geolocation_not_available: 'La geolocalizzazione non è disponibile.\nSi prega di controllare le impostazioni GPS.',
        no_results: 'Nessun Risultato'
    },
    /*faq: [
        {
            question: 'Cos\'è il progetto PORTOLANES?',
            answer: `Il Progetto PORTOLANES “Sviluppo Sostenibile del Turismo Nautico e Valorizzazione dei Piccoli Porti dello Ionio” fa parte dell'Interreg Grecia-Italia.
Il progetto PORTOLANES mira a sfruttare l'elevato potenziale turistico dell'area di intervento, attraverso lo sviluppo del turismo nautico in 21 porti di piccole e medie dimensioni (12 in Italia, 4 in Albania e 5 in Montenegro).
Ha lo scopo di collegare i porti selezionati con attività turistiche alternative legate ai beni naturali e culturali dell'entroterra, comprese aree di alto valore estetico ed ecologico, attività sportive e ricreative, siti archeologici e monumenti storici, nonché molteplici destinazioni tematiche concentrate su gastronomia, religione e cultura.
Il progetto creerà un piano integrato di sviluppo turistico che comprenderà pacchetti turistici attraenti, attraverso un approccio sostenibile rispetto all'ambiente naturale e umano, aggiornando i servizi attraverso l'utilizzo di nuove tecnologie che dovrebbero aumentare la quota turistica nelle aree selezionate.
Il Progetto si rivolge alla forte clientela del turismo nautico; tuttavia, l'approccio generale di PORTOLANES non si limita solo alle attività di turismo nautico, ma include anche destinazioni tematiche legate ai beni naturali e culturali dell'entroterra.
L'obiettivo generale del Progetto è quello di sviluppare un modello comune per la gestione intelligente e sostenibile delle NT attraverso piani di sviluppo integrati, considerando come priorità assoluta la sostenibilità ambientale.
PORTOLANES mira a fornire un approccio olistico nel:
 - collegare le zone costiere con le ricchezze dell'entroterra,
 - emergere e promuovere le aree interconnesse (Marchio d'Area, Percorsi Tematici), e
 - riqualificazione dei servizi e dei prodotti a supporto del turismo.
Il piano costituirà un approccio innovativo nel settore del turismo, che non può essere affrontato con successo dalle singole strategie nazionali. Il suo impatto previsto sarà di grande beneficio per le parti interessate nell'area di intervento, comprese le comunità locali, le organizzazioni nazionali e/o regionali e le imprese legate al turismo.
I principali risultati attesi includono:
 - Piano di sviluppo operativo del turismo nautico
 - Impianti di monitoraggio dell’ inquinamento
 - Piattaforma Operativa con Servizi Turistici Comuni
 - Costituzione di centri PORTOLANES
 - Strumenti per l'aggiornamento delle competenze turistiche.
Si prevede che i risultati aiutino la gestione intelliggente e sostenibile del turismo, aumentando così la popolazione turistica, stimolando la crescita finanziaria e riducendo la disoccupazione su scala locale/regionale. I risultati di cui sopra saranno raggiunti senza compromettere la sostenibilità ambientale. PORTOLANES aumenterà l'uso delle tecnologie ICT e migliorerà il profilo "low-tech" della regione transfrontaliera, sosterrà l'area di intervento nel competere con successo con altre destinazioni turistiche del Mediterraneo e, infine, interconnetterà le aree costiere con quelle interne e massimizzerà il loro potenziale.
`
        },
        {
            question: 'Cos\'è l\'app i-Tourist Guide?',
            answer: 'i-Tourist Guide è un\'app per la pianificazione dei viaggi all\'interno dei percorsi tematici PORTOLANES (porti e entroterra) in base a criteri definiti dall\'utente.'
        },
        {
            question: 'Per quali paesi fornisce informazioni la i-Tourist Guide?',
            answer: 'La i-Tourist Guide fornisce informazioni per tutti e tre i paesi partecipanti al Progetto PORTOLANES (Italia, Albania, Montenegro).'
        },
        {
            question: 'Posso selezionare una categoria di Percorsi Tematici?',
            answer: 'Sì. Puoi selezionare una Categoria di Itinerari Tematici, come Archeologici, Naturali, Costieri e molti altri.'
        },
        {
            question: 'Posso selezionare una categoria di Punti di Interesse?',
            answer: 'Sì. Puoi selezionare una categoria di punti di interesse come Porto, Natura, Città e molti altri.'
        },
        {
            question: 'Posso selezionare un punto di interesse specifico?',
            answer: 'Sì. È possibile selezionare un punto di interesse specifico della categoria selezionata di punti di interesse.'
        },
        {
            question: 'Posso porre limiti alla durata o alla lunghezza del Percorso Tematico?',
            answer: 'Sì. È possibile selezionare la lunghezza del viaggio (0-300 km) e il tempo totale del viaggio (0 – 24 ore).'
        },
        {
            question: 'Vengono prese in considerazione le funzioni di accessibilità?',
            answer: 'Sì. L\'i-Tourist Guide tiene conto dell\'accessibilità per gli Anziani, per i Portatori di Handicap e se i Percorsi Tematici sono o meno Child Friendly.'
        },
        {
            question: 'Posso vedere quanti Percorsi Tematici si adattano alle mie scelte?',
            answer: 'Sì. Un pulsante visualizza il numero dei Percorsi Tematici selezionati in base alle tue scelte.'
        },
        {
            question: 'Posso trovare maggiori dettagli sui Percorsi Tematici selezionati?',
            answer: 'Sì. Tutti i Percorsi Tematici selezionati possono essere presentati nel GIS, dove si possono trovare informazioni per ogni Punto di Interesse.'
        },
        {
            question: 'Se il GPS è abilitato sul mio dispositivo, mostrerà i percorsi tematici vicini a me?',
            answer: 'Sì. La i-Tourist Guide rileva la tua posizione e visualizza Percorsi Tematici che includono Punti di Interesse fino a 50 km di distanza da te.'
        }
    ]*/
    faq: [
        {
            question: 'Cos\'è il Progetto PORTOLANES?',
            answer: `“Portolanes – Sviluppo Sostenibile del Turismo Nautico e Valorizzazione dei Piccoli Porti dello Ionio” mira ad accrescere la nautica
            turismo che rappresenta un'industria ad alto valore aggiunto con forte trend di crescita nel Mar Adriatico e Ionio. In generale, il turismo nautico è di più
             popolare durante il periodo estivo, ma se adeguatamente combinato con altre attività turistiche avrà un impatto positivo per le economie locali e per la crescita economica regionale.
`
        },
        {
            question: 'Qual è l\'obiettivo principale del Progetto PORTOLANES?',
            answer: 'L\'obiettivo principale è la conservazione dell\'ambiente marino e costiero che comprende le risorse naturali che potenzialmente interagiscono con attività di turismo nautico mirate e la protezione dei beni naturali e culturali dell\'entroterra che si prevede saranno sfruttati dal turismo transfrontaliero.'
        },
        {
            question: 'Chi è il Lead Partner del Progetto PORTOLANES?',
            answer: 'L\'Unione Regionale delle Province della Puglia-UPI PUGLIA è Lead Partner del progetto.'
        },
        {
            question: 'Chi sono i partner del Progetto PORTOLANES?',
            answer: 'Il partner italiano è Confcommercio Puglia, mentre i tre partner greci sono il Ministero del Turismo, Unione Regionale dei Comuni dell\'Epiro e Rete delle Città Europee per lo Sviluppo Sostenibile.'
        }
    ]
}
