import {createAction, createReducer, createAsyncThunk} from "@reduxjs/toolkit";
import {getData} from "../../api/gisdata";
import {setCountries} from "./countries";
import {setPoints} from "./points";
import {setRoutes} from "./routes";
import {setCategories} from "./categories";
import {sortBy, uniq} from "lodash";
import {setRouteCategories} from "./routecategories";
import {setWays} from "./ways";

const loadApiData = createAsyncThunk('LOAD_API_DATA', async (payload = '', {dispatch, getState}) => {
    const locale = getState().settings.locale;

    const response = await getData(locale);
    const {data} = await response.json();

    //console.log('GIS DATA', {data});

    const sortedCountries = [
        data.countries.find(_ => _.code === 'it'),
        //data.countries.find(_ => _.code === 'al'),
        //data.countries.find(_ => _.code === 'me'),
        data.countries.find(_ => _.code === 'gr'),
    ];
    dispatch(setCountries(sortedCountries));

    const routePoints = [];

    data.routes.map(
        route => route.points.map(
            point => routePoints.push(point.id)
        )
    );

    dispatch(setPoints(
        data.points.filter(
            point => routePoints.includes(point.id)
        ).map(
            point => ({
                ...point,
                name: point.title
            })
        )
    ));

    dispatch(setRoutes(
        data.routes.map(
            route =>
                ({
                    ...route,
                    country: route.country_id,
                    points: route.points.map(_ => _.id)
                })
        )
    ));
    dispatch(setCategories(data.categories));

    dispatch(setRouteCategories(data.route_categories));

    dispatch(setWays(data.ways));
    return data;
});

const initialState = {
    en: {data: null, status: '', error: null},
    it: {data: null, status: '', error: null}
};

const reducer = createReducer(initialState, {
    [loadApiData.pending]: (state, {meta:{arg:locale}}) => {
        return {
            ...state,
            [locale]: {...state[locale], status: 'loading'}
        };
    },//({...state, [locale]: {state.locale, status: 'loading'}}),
    [loadApiData.rejected]: (state, {meta:{arg:locale}, error}) =>
    {
        return {
            ...state,
            [locale]: {...state[locale], status: 'error', error}
        };
    },
    [loadApiData.fulfilled]: (state, {payload, meta:{arg:locale}}) => {
        return {
            ...state,
            [locale]: {...state[locale], status: 'loaded', data: payload}
        }
    }
});

export {loadApiData};
export default reducer;