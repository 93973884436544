import {createAction, createReducer} from "@reduxjs/toolkit";

const setSearchData = createAction('SET_SEARCH_DATA');
const setSearchProp = createAction('SET_SEARCH_PROP');

//export const tripLengthBounds = {lower: 0, upper: 300};
//export const tripTimeBounds = {lower: 0, upper: 24};

const initialState = {
    initialized: false,
    countries: [],
    categories: [],
    //routes: [],
    //tripLength: tripLengthBounds,
    //tripTime: tripTimeBounds,
    accessibilityOptions: true,
    childrenFriendlyOptions: true
};

const reducer = createReducer(initialState, {
    [setSearchData]: (state, action) => action.payload,
    [setSearchProp]: (state, action) => ({...state, ...action.payload})
});

export {setSearchData, setSearchProp};
export default reducer;