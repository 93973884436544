import {createAction, createReducer} from "@reduxjs/toolkit";

const toggleFavorited = createAction('TOGGLE_FAVORITED');

const initialState = [];

const reducer = createReducer(initialState, {
    [toggleFavorited]: (state, action) => {
        if (state.includes(action.payload))
            return state.filter(i => i !== action.payload);

        return [...state, action.payload];
    }
});

export {toggleFavorited};
export default reducer;