import React from 'react'
//import logo from "../resources/logo.png";
//import logo from "../resources/samples/sample_logo.png";
import logo from "../resources/logo.png";
import axis from "../resources/samples/logo/axis-round-2.png";
import {toggleDarkAxis} from "../scripts/helpingFunctions.js";

const LogoHeader = () =>
    <div className='logo'>
        {<div><img src={logo}  /></div>}
        <div className='axis' id='axis'>
            <img src={axis} style={{width: 40, height:40}} />
            <div>
                <span style={{fontWeight: 'bold'}}>Axis</span><br />
                Integrated Environmental Management
            </div>
        </div>
    </div>;

export default LogoHeader;